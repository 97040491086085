<template>
  <li
    class="nav-list-item"
    :class="[
      { 'is-open': isOpenAccordion && hasAccordion && children.length },
      { 'is-level-3': level === 3 },
      { 'is-wide': isWide && children.length > 10 },
      { 'col-span-2': isWide && children.length > 10 && children.length <= 20 },
      { 'col-span-3': isWide && children.length > 20 && children.length <= 30 },
    ]"
  >
    <SlotLink
      v-if="url && (level === 3 || !children.length)"
      :url="url"
      :show-chevron="!hasAccordion"
      class="nav-list-item__link"
      :class="{ 'is-title': children.length, 'is-title-link': url }"
    >
      <div
        class="nav-list-item__title"
        :class="{ 'has-children': children.length, 'is-link-only': children?.length === 0, 'is-link': url }"
      >
        <span>{{ title }}</span>
        <AppIcon
          v-if="hasAccordion && children.length"
          class="nav-list-item__chevron"
          icon="icon-down"
          :is-raw="true"
        />
      </div>
    </SlotLink>
    <div
      v-else-if="!url && (level === 3 || !children.length)"
      class="nav-list-item__link"
      :class="{ 'is-title': children.length }"
    >
      <div
        class="nav-list-item__title"
        :class="{ 'has-children': children.length, 'is-link-only': children?.length === 0 }"
      >
        <span>{{ title }}</span>
        <AppIcon
          v-if="hasAccordion && children.length"
          class="nav-list-item__chevron"
          icon="icon-down"
          :is-raw="true"
        />
      </div>
    </div>
    <button
      v-else
      type="button"
      class="nav-list-item__link"
      @click="handleAccordion"
    >
      <span>{{ title }}</span>
      <AppIcon
        class="nav-list-item__chevron"
        icon="icon-down"
        :is-raw="true"
      />
    </button>
    <ul
      v-if="children.length"
      class="nav-list-item__children"
      :class="{
        'navlist-item__accordion-content is-accordion': hasAccordion,
      }"
    >
      <li
        v-for="(item, index) in children"
        :key="index"
        :style="{ gridColumn: getColumn(index), gridRow: getRow(index) }"
        class="nav-list-item__sub-item"
      >
        <button
          v-if="item.children && item.children.length > 0"
          type="button"
          class="nav-list-item__link"
          :class="{ 'is-active': activeLevel3Id === index }"
          @click="flyoutStore.updateLevel3Index(index)"
        >
          {{ item.title }}
        </button>
        <button
          v-else-if="item.brands"
          type="button"
          class="nav-list-item__link"
          :class="{ 'is-active': activeLevel3Id === index }"
          @click="flyoutStore.updateLevel3Index(index)"
        >
          {{ item.title }}
        </button>
        <SlotLink
          v-else-if="item.url"
          :url="item.url"
          :show-chevron="!hasAccordion"
          class="nav-list-item__link"
        >
          {{ item.title }}
        </SlotLink>
      </li>
    </ul>
  </li>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    url: {
        type: String,
        default: '',
    },
    children: {
        type: Array,
        default: () => [],
    },
    level: {
        type: Number,
        default: 0,
    },
    hasAccordion: {
        type: Boolean,
        default: false,
    },
    accordionIndex: {
        type: Number,
        default: 0,
    },
    accordionActiveIndex: {
        type: Number,
        default: -1,
    },
    isWide: {
        type: Boolean,
        default: false,
    },
});

const flyoutStore = useFlyoutStore();

const emit = defineEmits(['updateAccordionActiveIndex']);

const activeLevel3Id = computed(() => flyoutStore.getLevel3Index);
const isOpenAccordion = computed(() => props.accordionActiveIndex === props.accordionIndex);

const handleAccordion = () => {
    emit('updateAccordionActiveIndex', props.accordionIndex);
    flyoutStore.updateLevel2Index(props.accordionIndex);
};

const getColumn = (index) => {
    const maxItemsPerColumn = 10;
    return Math.floor(index / maxItemsPerColumn) + 1;
};
const getRow = (index) => {
    const maxItemsPerColumn = 10;
    return (index % maxItemsPerColumn) + 1;
};
</script>

<style lang="scss">
.nav-list-item__sub-item {
    @include typo-size('navLevel3');

    .is-wide & {
        margin-bottom: 0;
    }

    .nav-list-item__link {

        &.is-active {
            color: $C_GREEN_DARK;
        }
    }
}

.nav-list-item__children {
    .is-wide & {
        @include grid-layout(3);
    }
}

.nav-list-item {
    overflow: hidden;

    .is-wide & {
        margin-bottom: 0;
    }

    &.is-level-3 {
        @include typo-font('regular');

        color: $C_PRIMARY;

        .nav-list-item__link {
            margin-bottom: 10px;

            &.is-title:not(.is-title-link) {
                margin-bottom: 21px;
            }

            &.is-link {
                @include typo-size('navLevel3');
            }

            .has-children:not(.is-link) {
                @include typo-size('navLevel2');
            }

            .is-link-only, .is-link {
                @include typo-size('navLevel3');
            }
        }

    }

    .is-accordion {
        display: none !important;
    }

    &.has-chevron {
        display: flex;
    }

    .nav-list-item__chevron {
        width: 0.9rem;
        transform-origin: center;
        transition: transform 0.2s ease-in-out;
    }

    &.is-open {
        .nav-list-item__chevron {
            transform: rotate(180deg);
        }

        .is-accordion {
            display: block !important;
        }

        > .nav-list-item__link {
            color: $C_GREEN_DARK;
        }
    }

    .nav-mega-flyout__content-list--has-sub-items &.is-level-3 {
        > .nav-list-item__link {

            margin-bottom: 21px;

            > .nav-list-item__title {
                @include typo-size('navLevel2');

                margin: initial;
            }
        }
    }
}
</style>
